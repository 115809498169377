import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import bannerimage1 from '../../images/marketplace-image2.png';
import bannerimage2 from '../../images/web-development-infographics.png';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function App() {
  return (
    <>
	 <section className="banner-section">
        <section className="banner-three">
		<div class="slider-infos">
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>       <div className="container">
						<div className="row">
						<div className="col-md-6">
                            <div className="content">
<h1 data-animation="fadeInUp" data-delay="1s">Scale Your Online <br/>Store Worldwide</h1>
                                <p data-animation="fadeInUp" data-delay="1.3s">Power Dock Approach: E-Store Development, <br/>
Outreach, & Seamless Execution 🚀</p>
                                <a href="/request-free-audit" className="btn-one mt-40" data-animation="fadeInUp"
                                    data-delay="1.8s"><span>Request Free Audit</span></a>
                            </div>
                        </div><div className="col-md-6">
						 <div className="banner-image">
             <img src={bannerimage1} alt="" />
						 </div></div></div>
						  </div></SwiperSlide>
        <SwiperSlide>  <div className="container">
						<div className="row">
						<div className="col-md-6">
                            <div className="content">
<h1 data-animation="fadeInUp" data-delay="1s">Amplify Your Brand's <br/>Digital Presence</h1>
                                <p data-animation="fadeInUp" data-delay="1.3s">Strategic Blueprint for E-Commerce Excellence – Branding, Amazon Mastery, and Beyond</p>
                                <a href="/services" className="btn-one mt-40" data-animation="fadeInUp"
                                    data-delay="1.8s"><span>View More</span></a>
                            </div>
                        </div>
						<div className="col-md-6">
						 <div className="banner-image">
             <img src={bannerimage2} alt="" />
						 </div>
						 </div>
						 </div>
						  </div></SwiperSlide>
       
      </Swiper></div>
	  </section>

  </section>
    </>
  );
}
