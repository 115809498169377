import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    mobile: '',
    service: '',
    message: ''
  });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.from_name.trim()) {
      errors.from_name = 'Name is required';
    }
    if (!formData.from_email.trim()) {
      errors.from_email = 'Email is required';
    }
    if (!formData.mobile.trim()) {
      errors.mobile = 'Mobile is required';
    } else if (!/^\d{10}$/i.test(formData.mobile.trim())) {
      errors.mobile = 'Invalid mobile number';
    }
    if (!formData.service.trim()) {
      errors.service = 'Service is required';
    }
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      emailjs.sendForm('service_cd4v88n', 'template_x3ov99o', e.target, 'FJQCBS_CLw9C6DIym')
        .then(() => {
          setIsEmailSent(true);
          setFormData({
            from_name: '',
            from_email: '',
            mobile: '',
            service: '',
            message: ''
          });
        }, (error) => {
          console.error('Failed to send email:', error);
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      {isEmailSent ? (
        <div>
          <p className="submitted-successfully">Email successfully sent!</p>
          {/* Additional content or actions after successful email sending */}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          		
          <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="text" name="from_name" value={formData.from_name} onChange={handleChange} placeholder="Full Name*" className="form-control" />
            {errors.from_name && <span>{errors.from_name}</span>}
          </div>
		  </div>
          <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="email" name="from_email" value={formData.from_email} onChange={handleChange} placeholder="Email Address*" className="form-control" />
            {errors.from_email && <span>{errors.from_email}</span>}
          </div>
		  </div>

         <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Mobile Number*" className="form-control" />
            {errors.mobile && <span>{errors.mobile}</span>}
         </div>
		  </div>
         <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <select name="service" value={formData.service} onChange={handleChange} className="form-select">
              <option value="">Interested In</option>
              <option value="How do you sell your products?">How do you sell your products?</option>
              <option value="D2C Website">D2C Website</option>
              <option value="Marketplaces">Marketplaces</option>
			  <option value="Others">Others</option>
            </select>
            {errors.service && <span>{errors.service}</span>}
          </div>
		  </div>
          <div className={errors.from_name ? 'error form-style' : 'form-style'}>
          <div className="form-group">
            <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Your Message" className="form-control"></textarea>
            {errors.message && <span>{errors.message}</span>}
          </div></div>
		   <div className="form-style">
          <div className="form-group">
          <button type="submit" className="submit-btn btn">Submit Now</button>
		   </div></div>
	
        </form>
      )}
    </div>
  );
};

export default ContactForm;
