import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import AshishAgarwal from '../../images/Ashish-Agarwal.jpg';
import RachelPorter from '../../images/Rachel-Porter.jpg';
import mumandyou from '../../images/mumandyou-logo.png';
import tencha from '../../images/tencha-logo.png';
function Testimonials() {
    
  return (
    <>
         <section className="testimonials-section">
	<div className="container">
		<div className="section-header text-center m-20">
			<h2 className="wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".6s">It's not rare for us to hear statements like...</h2>
		</div>
		<div className="row">
		  <div className="col-lg-10 offset-lg-1">
                                <div className="swiper arry-wrp testimonial-slider2">
                                    <div className="swiper-wrapper">
                                        <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide> 
			<div className="item">
			<div className="testimonial-content">
			<div className="client-img"><img src={AshishAgarwal} alt="" /></div>
			<div className="client-description">
			<h4>Ashish Agarwal</h4>
			<p>Founder &amp; CEO at tencha</p>
			</div>
			<p>We've been working with EZ Commerce for 18 months now. The team is very responsive and has helped us profitably scale from zero to 5L+ MRR on Amazon India, enabling us to capture more than 20% market share of the matcha green tea market in India.</p>
			<div className="client-logo-img"><img src={tencha} alt="" /></div>
			</div>
			</div> 
		</SwiperSlide>
        <SwiperSlide> 
			<div className="item">
			<div className="testimonial-content">
			<div className="client-img"><img src={RachelPorter} alt="" /></div>
			<div className="client-description">
			<h4>Rachel Porter</h4>
			<p>Marketing Head at Mum and You</p>
			</div>
			<p>EZ Commerce had build and has been maintaining our site for over one and a half years now and they have created nappychat iOS &amp; Android based apps for us and I must tell you that I’m so happy with their professionalism, timely deliveries and quality of work. I highly recommend them.
			</p>
			<div className="client-logo-img"><img src={mumandyou} alt="" /></div>
			</div>
			</div>
		</SwiperSlide>
        
        
      </Swiper>
                                    </div>
                                    
									
                                </div>
                            </div>
		</div>
	</div>
</section>


		
    </>
  );
}
export default Testimonials;
