import { React } from "react";
import pfeature1 from '../../images/pfeature-01.png';
import pfeature2 from '../../images/businessman.png';
import pfeature3 from '../../images/financial-statement.png';
import pfeature4 from '../../images/market-research.png';
import pfeature5 from '../../images/flexibility.png';
import pfeature6 from '../../images/adaptation.png';

function Perks() {
    
  return (
    <>
         <section class="perks-section">       
            <div class="container">
               <div class="section-header text-center m-40">
                  <h2 class="wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".6s">EZ Commerce Perks</h2>
				  <p>A Century of Marketing Excellence & Expertise at Your Service.</p>
                </div>
				<div class="row">
					<div class="col-lg-4 col-md-6">
						<div class="perks-features">
							<div class="perks-features-icon">
								<img src={pfeature1} alt="" />
							</div>
							<h4>Unmatched End-To-End System</h4>
							<p>From Building Top-Notch Webstores to Worldwide Brand Boosting, Serving All Brand Sizes...</p>
						</div>
					</div>	
					<div class="col-lg-4 col-md-6">
						<div class="perks-features">
							<div class="perks-features-icon">
								<img src={pfeature2} alt="" />
							</div>
							<h4>Power Dock Start-Up Excellence</h4>
							<p>Propel Your Brand to New Heights, Guiding You Every Step of the Journey.</p>
						</div>
					</div>
					<div class="col-lg-4 col-md-6">
						<div class="perks-features">
							<div class="perks-features-icon">
							<img src={pfeature3} alt="" />
							</div>
							<h4>Revenue & Returns Centric</h4>
							<p>Ensuring Efficiency, Driving Growth without Compromise</p>
						</div>
					</div>	
					<div class="col-lg-4 col-md-6">
						<div class="perks-features">
							<div class="perks-features-icon">
								<img src={pfeature4} alt="" />
							</div>
							<h4>Empowered By Data Insights</h4>
							<p>Power Dock Analytics: Every Insight Captured, Every Brand Empowered</p>
						</div>
					</div>
					<div class="col-lg-4 col-md-6">
						<div class="perks-features">
							<div class="perks-features-icon">
								<img src={pfeature5} alt="" />
							</div>
							<h4>Flexible & Proactive</h4>
							<p>Masterful Tech & Ops Crew: Best Practices for Swift Launches...</p>
						</div>
					</div>	
					<div class="col-lg-4 col-md-6">
						<div class="perks-features">
							<div class="perks-features-icon">
								<img src={pfeature6} alt="" />
							</div>
							<h4>Adaptable & Versatile</h4>
							<p>Power Dock Work-Flows Tailored Just For Your Brand</p>
						</div>
					</div>
				</div>
			</div>
        </section>
		
    </>
  );
}
export default Perks;
