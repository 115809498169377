import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const AuditForm = () => {
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    mobile: '',
    business_name: '',
	business_website: '',
	amazon_brand_store: '',
    message: ''
  });
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.from_name.trim()) {
      errors.from_name = 'Name is required';
    }
    if (!formData.from_email.trim()) {
      errors.from_email = 'Email is required';
    }
    if (!formData.mobile.trim()) {
      errors.mobile = 'Mobile is required';
    } else if (!/^\d{10}$/i.test(formData.mobile.trim())) {
      errors.mobile = 'Invalid mobile number';
    }
    if (!formData.business_name.trim()) {
      errors.business_name = 'Business name is required';
    }
	if (!formData.business_website.trim()) {
      errors.business_website = 'Business website is required';
    }
	if (!formData.amazon_brand_store.trim()) {
      errors.amazon_brand_store = 'Amazon brand store page is required';
    }
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  const handleSubmit = e => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      emailjs.sendForm('service_cd4v88n', 'template_4piqo7m', e.target, 'FJQCBS_CLw9C6DIym')
        .then(() => {
          setIsEmailSent(true);
          setFormData({
            from_name: '',
            from_email: '',
            mobile: '',
            business_name: '',
			amazon_brand_store: '',
            business_website: '',
            message: ''
          });
        }, (error) => {
          console.error('Failed to send email:', error);
        });
    } else {
      setErrors(errors);
    }
  };

  return (
    <div>
      {isEmailSent ? (
        <div>
          <p className="submitted-successfully">Email successfully sent!</p>
          {/* Additional content or actions after successful email sending */}
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          		
          <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="text" name="business_name" value={formData.business_name} onChange={handleChange} placeholder="Business Name*" className="form-control" />
            {errors.business_name && <span>{errors.business_name}</span>}
          </div>
		  </div>

		  <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="text" name="business_website" value={formData.business_website} onChange={handleChange} placeholder="Business Website*" className="form-control" />
            {errors.business_website && <span>{errors.business_website}</span>}
          </div>
		  </div>
		   <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <select name="amazon_brand_store" value={formData.amazon_brand_store} onChange={handleChange} className="form-select">
              <option value="Amazon Brand store page?">Amazon Brand store page?</option>
              <option value="Copy and paste the URL">Copy and paste the URL</option>
			  <option value="Don&#039;t have a brand store page">Don&#039;t have a brand store page</option>
            </select>
            {errors.amazon_brand_store && <span>{errors.amazon_brand_store}</span>}
          </div>
		  </div>
		  <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="text" name="from_name" value={formData.from_name} onChange={handleChange} placeholder="Full Name*" className="form-control" />
            {errors.from_name && <span>{errors.from_name}</span>}
          </div>
		  </div>
          <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="email" name="from_email" value={formData.from_email} onChange={handleChange} placeholder="Email Address*" className="form-control" />
            {errors.from_email && <span>{errors.from_email}</span>}
          </div>
		  </div>

         <div className={errors.from_name ? 'error form-style' : 'form-style'}>
			<div className="form-group">
            <input type="tel" name="mobile" value={formData.mobile} onChange={handleChange} placeholder="Mobile Number*" className="form-control" />
            {errors.mobile && <span>{errors.mobile}</span>}
         </div>
		  </div>
        
          <div className={errors.from_name ? 'error form-style' : 'form-style'}>
          <div className="form-group">
            <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Tell Us Briefly About Your Needs..." className="form-control"></textarea>
            {errors.message && <span>{errors.message}</span>}
          </div></div>
		   <div className="form-style">
          <div className="form-group">
          <button type="submit" className="submit-btn btn">Submit Now</button>
		   </div></div>
	
        </form>
      )}
    </div>
  );
};

export default AuditForm;
