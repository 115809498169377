import React from 'react';

const FirstPageDetails = ({ page }) => {
  return (
    <div className="container mt-5">
        <div className="row">
            <div className="col-md-12">
                <h2>{page.title.rendered}</h2>
				<h1>{page.acf.short_title}</h1>
					<p>{page.acf.short_sub_title}</p>
                <div dangerouslySetInnerHTML={{ __html: page.content.rendered }} />
				<div className="col-lg-10 offset-lg-1" dangerouslySetInnerHTML={{ __html: page.acf.extra_content }} />
                </div>
        </div>
    </div>
  );
};

export default FirstPageDetails;
