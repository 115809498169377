import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavbarComponent from './components/Navbar';
import HomePage from './components/Home/HomePage';
import BlogPost from './components/BlogPost';
import BlogPostDetails from './components/BlogPostDetails';
import FirstPageDetails from './pages/FirstPageDetails';
import PageDetails from './pages/PageDetails';
import ContactUsPage from './pages/ContactUsPage';
import AboutUsPage from './pages/AboutUsPage';
import ServicesPage from './pages/ServicesPage';
import RequestFreeAduitPage from './pages/RequestFreeAduitPage';
import CaseStudyPage from './pages/CaseStudyPage';
import CaseStudyPageDetails from './pages/CaseStudyPageDetails';
import CaseStudyPageDetail from './pages/CaseStudyPageDetail';
import ContactForm from './pages/ContactForm';
import Footer from './components/Footer';
import wordpressApiUrl from './config';
import 'bootstrap/dist/css/bootstrap.min.css';
import uparrow from './images/up-arrow.png';
import './css/style.css';
import './App.css';

const App = () => {
  const [blogPages, setBlogPages] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await fetch(`${wordpressApiUrl}/pages?_embed`);
        const pagesData = await response.json();
        setBlogPages(pagesData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPages();

    // Event listener for scrolling
    window.addEventListener('scroll', handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setShowScrollToTop(true);
    } else {
      setShowScrollToTop(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Filter out privacy, imprint, and contact pages for the header navigation
  const navbarPages = blogPages.filter(
    page => !['privacy', 'imprint', 'contact1'].includes(page.slug)
  );

  return (
    <Router>
      <div className="App">
        <header className="header"> 
          <NavbarComponent blogPages={navbarPages} />
        </header>
        <main className="App-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
			<Route path="/category/blog/" element={<BlogPost />} />
            <Route path="/post/:postId" element={<BlogPostDetails />} />
            <Route path="/first-page" element={<FirstPageDetails />} />
			<Route path="/contact-us" element={<ContactUsPage />} />
			<Route path="/about-us" element={<AboutUsPage />} />
			<Route path="/services" element={<ServicesPage />} />
			<Route path="/request-free-audit" element={<RequestFreeAduitPage />} />
			<Route path="/case-studies" element={<CaseStudyPage />} />
			<Route path="/my-direct-to-consumer-website-case-study" element={<CaseStudyPageDetails />} />
			<Route path="/mumandyou-amazon-advertising-case-study" element={<CaseStudyPageDetail />} />
			<Route path="/contact" element={<ContactForm />} />
            {blogPages.map((page) => (
              <Route
                key={page.id}
                path={`/${page.slug}`}
                element={<PageDetails page={page} />}
              />
            ))}
          </Routes>
        </main>
        <Footer />
        {showScrollToTop && (
          <button className="scroll-to-top" onClick={scrollToTop}>
            <img src={uparrow} alt="" />
          </button>
        )}
      </div>
    </Router>
  );
};

export default App;
