import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import { Tab, Nav } from 'react-bootstrap';

import linkimg from '../../images/link.png';
import flagsportfolio from '../../images/12flags-portfolio.png';
import blinkit from '../../images/blinkit-logo.png';
import mumandyou from '../../images/mumandyou-logo.png';
import captainzack from '../../images/captainzack-logo.png';
import tencha from '../../images/tencha-logo.png';
import yufoodlabs from '../../images/yufoodlabs-logo.png';
import earthrhythm from '../../images/earthrhythm-logo.png';
import drsheths from '../../images/drsheths-logo.png';
import decoberry from '../../images/decoberry-logo.png';
import justherbs from '../../images/justherbs-logo.png';
import justherbsportfolio from '../../images/justherbs-portfolio.png';
import roohworld from '../../images/roohworld-logo.png';
import naturalis from '../../images/naturalis-logo.png';
import naturalisportfolio from '../../images/naturalis-portfolio.png';
import penguinhomeglobal from '../../images/penguinhomeglobal-logo.png';
import pettofoods from '../../images/pettofoods-logo.png';
import pettofoodsportfolio from '../../images/pettofoods-portfolio.png';
import marketpartner from '../../images/marketpartner-logo.png';
import fieldassist from '../../images/fieldassist-logo.png';
import fieldassistportfolio from '../../images/fieldassist-portfolio.png';
import clensta from '../../images/clensta-logo.png';
import clenstab from '../../images/clensta-logo-b.png';
import clenstaportfolio from '../../images/clensta-portfolio.png';
import sensbodycare from '../../images/sensbodycare-logo.png';
import goodmatchit from '../../images/goodmatchit-logo.png';
import goodmatchitportfolio from '../../images/goodmatchit-portfolio.png';
import sensbodycareportfolio from '../../images/sensbodycare-portfolio.png';
import marketpartnerportfolio from '../../images/marketpartner-portfolio.png';
import flags from '../../images/12flags-logo.png';
import spotlightsearch from '../../images/spotlightsearch-logo.png';
import uk from '../../images/united-kingdom.png';
import india from '../../images/india.png';
import italy from '../../images/italy.png';
import penguinhomeglobalportfolio from '../../images/penguinhomeglobal-portfolio.png';
import roohworldportfolio from '../../images/roohworld-portfolio.png';
import stridesportfolio from '../../images/strides-portfolio.png';
import strides from '../../images/strides.png';
import australia from '../../images/australia.png';
import tenchaportfolio from '../../images/tencha-portfolio.png';
import yufoodlabsportfolio from '../../images/yufoodlabs-portfolio.png';
import mumandyouportfolio from '../../images/mumandyou-portfolio.png';
import blinkitportfolio from '../../images/blinkit-portfolio.png';
import earthrhythmportfolio from '../../images/earthrhythm-portfolio.png';
import captainzackportfolio from '../../images/captainzack-portfolio.png';
import drshethsportfolio from '../../images/drsheths-portfolio.png';
const Portfolio = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
  <section className="portfolio-section">
<div className="container">
<div className="section-header text-center m-40">
<h2 className="wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".6s">E-Commerce Mastery Across Borders: <span>Our Country Portfolio</span></h2>
<p>US, UK, EU, India, Australia, Canada</p>
</div>
</div>
<div className="portfolio-tab">
    <Tab.Container activeKey={activeTab} onSelect={handleTabChange}>
      <Nav variant="tabs" className="nav nav-pills mb-4 pb-4 justify-content-center">
        <Nav.Item>
          <Nav.Link eventKey="tab1">All</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="tab2">Baby</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="tab3">Food</Nav.Link>
        </Nav.Item>
		<Nav.Item>
          <Nav.Link eventKey="tab4">Health & Personal Care</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="tab5">Home & Kitchen</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="tab6">Finance</Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="tab1">
		<div className="portfolio-item-list">
						    <div className="swiper brand-slider">
                    <div className="swiper-wrapper">
          <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide> 
			<div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={mumandyou} alt="" />
<img src={uk} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Premium performing, eco-friendly, plastic free, biodegradable baby and skincare products by Mum and You. Free next day delivery on all subscriptions.</p>
<p className="hashtag">#EcommerceDevelopment #webdesign #CustomWebsiteDevelopment</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://mumandyou.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={mumandyouportfolio} alt="" />
</div>
</div>
</div>
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={blinkitportfolio} alt="" />
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={blinkit} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Shop online for groceries and get your order delivered at your doorstep in minutes. Enjoy instant delivery with blinkit.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://blinkit.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>

</div>
		</SwiperSlide>
        <SwiperSlide> 
			<div className="row">
<div className="col-md-6">
	<div className="portfolio-item-content">
		<div className="portfolio-item-content-logo">
		<img src={flags} alt="" /> 
		<img src={uk} alt="" className="portfolio-item-content-flag" /> 
		</div>
		<p>A unique platform to invest patient capital behind the most promising consumer businesses</p>
		<p className="hashtag">#EcommerceDevelopment #webdesign #CustomWebsiteDevelopment</p>
		<div className="link"><img src={linkimg} alt="" /> 
		<a href="https://12flags.com/" target="_blank" rel="nofollow">Visit Website</a></div>
	</div>
</div>
<div className="col-md-6">
	<div className="portfolio-item-image">
	<img src={flagsportfolio} alt="" /> 
	</div>
 </div>
</div>
<div className="row">
<div className="col-md-6">
	<div className="portfolio-item-image">
	<img src={goodmatchitportfolio} alt="" /> 
	</div>
 </div>
<div className="col-md-6">
	<div className="portfolio-item-content">
		<div className="portfolio-item-content-logo">
		<img src={goodmatchit} alt="" />
		<img src={italy} alt="" className="portfolio-item-content-flag" /> 
		</div>
		<p>Goodmatch has been designing and producing by hand since 1964 in Parma, in the middle of the Italian Fashion Valley. </p>
		<p className="hashtag">#EcommerceDevelopment #Redesign #webdesign</p>
		<div className="link"><img src={linkimg} alt="" />
		<a href="https://www.goodmatch.it/" target="_blank" rel="nofollow">Visit Website</a></div>
	</div>
</div>
</div>

		</SwiperSlide>
        <SwiperSlide> <div className="row">
<div className="col-md-6">
	<div className="portfolio-item-content">
		<div className="portfolio-item-content-logo">
		<img src={sensbodycare} alt="" />
		<img src={uk} alt="" className="portfolio-item-content-flag" /> 
		</div>
		<p>They contain highly natural, vegan ingredients which are formulated to be gentle but super effective. </p>
		<p className="hashtag">#EcommerceDevelopment #webdesign #CustomWebsiteDevelopment</p>
		<div className="link"><img src={linkimg} alt="" /> 
		<a href="https://sensbodycare.com/" target="_blank" rel="nofollow">Visit Website</a></div>
	</div>
</div>
 <div className="col-md-6">
	<div className="portfolio-item-image">
	<img src={sensbodycareportfolio} alt="" />
	</div>
 </div>
</div>
<div className="row">
<div className="col-md-6">
	<div className="portfolio-item-image">
	<img src={marketpartnerportfolio} alt="" />
	</div>
 </div>
<div className="col-md-6">
	<div className="portfolio-item-content">
		<div className="portfolio-item-content-logo">
		<img src={marketpartner} alt="" />
		<img src={uk} alt="" className="portfolio-item-content-flag" />
		</div>
		<p>Harness the power of technology and engage with decision makers to do amazing things.</p>
		<p className="hashtag">#EcommerceDevelopment #webdesign #CustomWebsiteDevelopment</p>
		<div className="link"><img src={linkimg} alt="" /> <a href="https://marketpartner.com/" target="_blank" rel="nofollow">Visit Website</a></div>
	</div>
</div>
</div></SwiperSlide>
		<SwiperSlide> <div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={strides} alt="" />
<img src={australia} alt="" className="portfolio-item-content-flag" /> 
</div>
<p> Strides Pharma Science Limited is a pharmaceutical company with a major focus on development and manufacture of IP-led niche finished dosage formulations. </p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://www.strides.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={stridesportfolio} alt="" />
</div>
</div>
</div>

<div className="row">


<div className="col-md-6">
<div className="portfolio-item-image">
<img src={penguinhomeglobalportfolio} alt="" /> 
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={penguinhomeglobal} alt="" />
<img src={uk} alt="" className="portfolio-item-content-flag" /> 
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Discover our modern and contemporary homeware items to revitalize your living space. Explore a wide range of home decor products and much more at Penguin Home.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /> 
<a href="https://www.penguinhomeglobal.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
</div> </SwiperSlide>
		<SwiperSlide> <div class="row">
<div class="col-md-6">
<div class="portfolio-item-content">
<div class="portfolio-item-content-logo">
<img src={earthrhythm} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Earth Rhythm is India’s 1st homegrown brand for smart and safe skincare. Visit and order today for the best skincare cosmetics by EarthRhythm.</p>
<p class="hashtag">#EcommerceDevelopment #webdesign #CustomWebsiteDevelopment</p>
<div class="link"><img src={linkimg} alt="" /><a href="https://earthrhythm.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div class="col-md-6">
<div class="portfolio-item-image">
<img src={earthrhythmportfolio} alt="" />
</div>
</div>
</div>
<div class="row">
<div class="col-md-6">
<div class="portfolio-item-image">
<img src={captainzackportfolio} alt="" />
</div>
</div>
<div class="col-md-6">
<div class="portfolio-item-content">
<div class="portfolio-item-content-logo">
<img src={captainzack} width="90px" alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Pet Care Products, Pet Supply Store Captain Zack is India’s leading pet supply store with high-quality and safest pet care products.</p>
<p class="hashtag">#EcommerceDevelopment #Redesign #AmazonMarketing #webdesign #organicgrowth</p>
<div class="link"><img src={linkimg} alt="" /><a href="https://captainzack.in/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
</div> </SwiperSlide>
		<SwiperSlide> <div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={yufoodlabs} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Yu has crafted a wide range of delicious Instant Meal Bowls that contain ZERO preservatives, additives, artificial flavorings or colors. </p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://yufoodlabs.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={yufoodlabsportfolio} alt="" />
</div>
</div>
</div>
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={drshethsportfolio} alt="" />
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={drsheths} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Shop trusted skin care &amp;amp; beauty products online. Shop from a wide range of Dr. Sheth&#39;s Luxurious Vegan,non-toxic &amp;amp; cruelty free Skincare products &amp;amp; Beauty Solutions meant exclusively for all skin types of India</p>
<p className="hashtag">#EcommerceDevelopment #Redesign #webdesign</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://www.drsheths.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>

</div> </SwiperSlide>
		<SwiperSlide> <div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={tenchaportfolio} alt="" />
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={tencha} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Buy authentic 100% Japanese Matcha Green Tea blends online. Natural &amp; Clean ingredients. Matcha powder is made from Japanese green tea leaves called &#39;Tencha&#39;.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://www.tencha.in/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
</div>
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={justherbsportfolio} alt="" />
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={justherbs} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" />
</div>
<p>Just Herbs - An Official Indian e-store for herbal, organic &amp; ayurvedic cosmetic &amp; beauty products for skincare, hair, bath &amp; body.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://www.justherbs.in/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>

</div> </SwiperSlide>
		<SwiperSlide> <div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={roohworld} alt="" /> 
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>These beautiful pieces of Dream Catchers are hand woven by the women artisans of India thereby supporting our nations ‘Make in India’ Campaign.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /> <a href="https://roohworld.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={roohworldportfolio} alt="" /> 
</div>
</div>
</div> 
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">

<img src={naturalisportfolio} alt="" />
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={naturalis} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Our mission is to provide the best quality Natural Essential Oil, Aromatherapy Essential Oils, Handmade Soaps to every home to offer an incredible array of benefits.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://naturalis.co.in/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>

</div> </SwiperSlide>
		<SwiperSlide> <div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={fieldassist} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" />
</div>
<p>Drive better GTM strategies with our sales and distribution software that can improve efficiency, productivity &amp; profitability for your business. </p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://www.fieldassist.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={fieldassistportfolio} alt="" />
</div>
</div>
</div>
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={clenstaportfolio} alt="" />
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={clenstab} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" />
</div>
<p>Clensta is India&#39;s 1st Personal Care Brand Infused with the Goodness of Nature &amp; Backed by Technology.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://clensta.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>

</div> </SwiperSlide>
		<SwiperSlide> <div class="row">
<div class="col-md-6">
<div class="portfolio-item-content">
<div class="portfolio-item-content-logo">
<img src={pettofoods} alt="" />
<img src={uk} alt="" className="portfolio-item-content-flag" />
</div>
<p>Like for humans, a diet based on plants and insects is significantly better for our furry friends - better digestion, blood circulation and immune system.</p>
<p class="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div class="link"><img src={linkimg} alt="" /><a href="https://pettofoods.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div class="col-md-6">
<div class="portfolio-item-image">
<img src={pettofoodsportfolio} alt="" />
</div>
</div>
</div> </SwiperSlide>
        
      </Swiper>
	  </div>
</div>

</div>
        </Tab.Pane>
        <Tab.Pane eventKey="tab2">
         
<div className="portfolio-item-list">
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={mumandyou} alt="" />
<img src={uk} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Premium performing, eco-friendly, plastic free, biodegradable baby and skincare products by Mum and You. Free next day delivery on all subscriptions.</p>
<p className="hashtag">#EcommerceDevelopment #webdesign #CustomWebsiteDevelopment</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://mumandyou.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={mumandyouportfolio} alt="" />
</div>
</div>
</div>
</div>
						
        </Tab.Pane>
        <Tab.Pane eventKey="tab3">
         
<div className="portfolio-item-list">
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={yufoodlabs} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Yu has crafted a wide range of delicious Instant Meal Bowls that contain ZERO preservatives, additives, artificial flavorings or colors. </p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://yufoodlabs.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={yufoodlabsportfolio} alt="" />
</div>
</div>
</div>
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={tenchaportfolio} alt="" />
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={tencha} alt="" />
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Buy authentic 100% Japanese Matcha Green Tea blends online. Natural &amp; Clean ingredients. Matcha powder is made from Japanese green tea leaves called &#39;Tencha&#39;.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://www.tencha.in/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
</div>
</div>


        </Tab.Pane>
		<Tab.Pane eventKey="tab4">
       
<div className="portfolio-item-list">
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={strides} alt="" />
<img src={australia} alt="" className="portfolio-item-content-flag" /> 
</div>
<p> Strides Pharma Science Limited is a pharmaceutical company with a major focus on development and manufacture of IP-led niche finished dosage formulations. </p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /><a href="https://www.strides.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={stridesportfolio} alt="" />
</div>
</div>
</div>
</div>


        </Tab.Pane>
        <Tab.Pane eventKey="tab5">
          
<div className="portfolio-item-list">
<div className="row">

<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={penguinhomeglobal} alt="" />
<img src={uk} alt="" className="portfolio-item-content-flag" /> 
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>Discover our modern and contemporary homeware items to revitalize your living space. Explore a wide range of home decor products and much more at Penguin Home.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /> 
<a href="https://www.penguinhomeglobal.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={penguinhomeglobalportfolio} alt="" /> 
</div>
</div>
</div>
<div className="row">
<div className="col-md-6">
<div className="portfolio-item-image">
<img src={roohworldportfolio} alt="" /> 
</div>
</div>
<div className="col-md-6">
<div className="portfolio-item-content">
<div className="portfolio-item-content-logo">
<img src={roohworld} alt="" /> 
<img src={india} alt="" className="portfolio-item-content-flag" /> 
</div>
<p>These beautiful pieces of Dream Catchers are hand woven by the women artisans of India thereby supporting our nations ‘Make in India’ Campaign.</p>
<p className="hashtag">#AmazonMarketing #webdesign #organicgrowth</p>
<div className="link"><img src={linkimg} alt="" /> <a href="https://roohworld.com/" target="_blank" rel="nofollow">Visit Website</a></div>
</div>
</div>

</div>   
</div>

        </Tab.Pane>
        <Tab.Pane eventKey="tab6">
          <div className="portfolio-item-list">
 <div className="row">
<div className="col-md-6">
	<div className="portfolio-item-content">
		<div className="portfolio-item-content-logo">
		<img src={flags} alt="" /> 
		<img src={uk} alt="" className="portfolio-item-content-flag" /> 
		</div>
		<p>A unique platform to invest patient capital behind the most promising consumer businesses</p>
		<p className="hashtag">#EcommerceDevelopment #webdesign #CustomWebsiteDevelopment</p>
		<div className="link"><img src={linkimg} alt="" /> 
		<a href="https://12flags.com/" target="_blank" rel="nofollow">Visit Website</a></div>
	</div>
</div>
  <div className="col-md-6">
	<div className="portfolio-item-image">
	<img src={flagsportfolio} alt="" /> 
	</div>
 </div>
</div>
</div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
	</div>
	</section>
  );
};

export default Portfolio;
