import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';
import blinkit from '../../images/blinkit-logo.png';
import mumandyou from '../../images/mumandyou-logo.png';
import captainzack from '../../images/captainzack-logo.png';
import tencha from '../../images/tencha-logo.png';
import yufoodlabs from '../../images/yufoodlabs-logo.png';
import earthrhythm from '../../images/earthrhythm-logo.png';
import drsheths from '../../images/drsheths-logo.png';
import decoberry from '../../images/decoberry-logo.png';
import justherbs from '../../images/justherbs-logo.png';
import roohworld from '../../images/roohworld-logo.png';
import naturalis from '../../images/naturalis-logo.png';
import penguinhomeglobal from '../../images/penguinhomeglobal-logo.png';
import pettofoods from '../../images/pettofoods-logo.png';
import marketpartner from '../../images/marketpartner-logo.png';
import fieldassist from '../../images/fieldassist-logo.png';
import clensta from '../../images/clensta-logo.png';
import sensbodycare from '../../images/sensbodycare-logo.png';
import goodmatchit from '../../images/goodmatchit-logo.png';
import flags from '../../images/12flags-logo.png';
import spotlightsearch from '../../images/spotlightsearch-logo.png';
export default function App() {
  return (
    <>
	<section className="trust bg-image section-padding">       
            <div className="container">
                <h4 className="mb-40 text-center">Excellence is Our Standard</h4>
                <div className="item">
                    <div className="swiper trust-slider">
                        <div className="swiper-wrapper">
      <Swiper
        slidesPerView={5}
        spaceBetween={30}
        pagination={{
          clickable: true,
        }}
		pagination={false}
		autoplay={{
          delay: 50000,
          disableOnInteraction: false,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide> <img src={blinkit} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={mumandyou} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={captainzack} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={tencha} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={yufoodlabs} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={earthrhythm} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={drsheths} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={decoberry} alt="" /> </SwiperSlide>
        <SwiperSlide> <img src={justherbs} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={roohworld} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={naturalis} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={penguinhomeglobal} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={pettofoods} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={marketpartner} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={fieldassist} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={clensta} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={sensbodycare} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={goodmatchit} alt="" /> </SwiperSlide>
		<SwiperSlide> <img src={flags} alt="" /> </SwiperSlide>
        <SwiperSlide><img src={spotlightsearch} alt="" /> </SwiperSlide>
        
      </Swiper>
	  </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}
