import { React } from "react";
import businessimg from '../../images/business-img.png';

function BrandDock() {
    
  return (
    <>
        <section className="powerdeck-section">       
			<div className="container">
			<div className="row">
			<div className="col-lg-7 col-md-8">
			<div className="powerdeck-content">
			<h2>Want to know how to take off your Sales? We're Your Brand Dock <span>Guide</span> :)</h2>
			<a href="/contact-us">Know More</a>
			</div>
			</div>
			<div className="col-lg-5 col-md-4">
			<div className="powerdeck-image"><img src={businessimg} alt="" />
			</div>				   
			</div>
			</div>
			</div>
        </section>
    </>
  );
}
export default BrandDock;
