import { React } from "react";
import shape2 from '../../images/shape-2.png';
import shape3 from '../../images/shape3.png';

function Mastery() {
    
  return (
    <>
         <div className="mastery-section service">
		<div className="container">
			<div className="row dream1-bg">
				<div className="col-lg-6">
					<div className="row">
						<div className="col-lg-12">
							<div className="section-header text-left m-20 padding-lg1">
							 <h2 className="wow fadeInUp" data-wow-duration="1.4s" data-wow-delay=".4s">E-Commerce Mastery:</h2>
							  <h2 className="wow fadeInUp pb-4" data-wow-duration="1.6s" data-wow-delay=".6s">India to the World and Back.</h2>
							
								<p>Mastering e-commerce requires a combination of technical skills, business acumen, and a customer-centric approach.</p>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="single-service-box upper2">
								<div className="service-content">
									<h3 className="service-title">Go Global:</h3>
									<p className="service-desc">Expand your Indian brand worldwide.</p>
									<a className="ez-button d-none" href="#">Read More <i className="fa-solid fa-arrow-right"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-lg-6">
					<div className="row">
						<div className="col-lg-12">
							<div className="single-service-box upper">
								<div className="service-content">
									<h3 className="service-title">Global to Local:</h3>
									<p className="service-desc">Introduce international products to the Indian market.</p>
									<a className="ez-button d-none" href="#">Read More <i className="fa-solid fa-arrow-right"></i></a>
								</div>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="single-service-box upper3">
								<div className="service-content">
									<h3 className="service-title">Global Healthcare Brand:</h3>
									<p className="service-desc">Global reach, Australia expertise.</p>
									<a className="ez-button d-none"  href="#">Read More <i className="fas fa-arrow-right"></i></a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="service-shape">
					<div className="serivce-shape">
						<img src={shape2} alt="" />
					</div>
					<div className="serivce-shape2">
						<img src={shape3} alt="" />
					</div>
				</div>
			</div>
		</div>
	</div>

		
    </>
  );
}
export default Mastery;
