import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import wordpressApiUrl from '../../config';
import Banner from './Banner';
import Clients from './Clients';
import Powerhouse from './Powerhouse';
import BrandDock from './BrandDock';
import Portfolio from './Portfolio';
import Perks from './Perks';
import Mastery from './Mastery';
import Testimonials from './Testimonials';

const HomePage = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(`${wordpressApiUrl}/posts?_embed&per_page=4`);
        const postData = await response.json();
        setBlogPosts(postData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setError('Error fetching blog posts');
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <main>
      <Helmet>
        <title>EZ Commerce &#8211; Growth as a Service (GaaS)</title>
        <meta name="description" content="Welcome to our blog! Read the latest posts on various topics." />
      </Helmet>
      <Banner />
      <Clients />
      <Powerhouse />
      <BrandDock />
      <Portfolio />
      <Perks />
      <Mastery />
      <Testimonials />
    </main>
  );
};

export default HomePage;
