import { React } from "react";
import featureimg1 from '../../images/image-feature-01.png';
import featureimg2 from '../../images/image-feature-02.png';
import featureimg3 from '../../images/image-feature-03.png';
import featureimg4 from '../../images/image-feature-04.png';

function Powerhouse() {
    
  return (
    <>
        <section className="powerhouse-section section-padding">       
            <div className="container">
               <div className="section-header text-center m-40">
                  <h2 className="wow fadeInUp" data-wow-duration="1.6s" data-wow-delay=".6s">The Only E-Commerce Powerhouse You'll Need</h2>
				  <p>Strategies to Launch Your Brand Globally with Impact</p>
                </div>
				<div className="row">
					<div className="col-lg-3 col-sm-6">
						<div className="feature-single-box">
							<div className="feature-number">1</div>
							<div className="feature-separator"></div>
							<div className="feature-single-box-content">
								<div className="feature-icon">
									<img src={featureimg4} alt="" />
								</div>
								<div className="feature-content">
									<h3>Brand <br/>Showcase</h3>
									<p>Tailored, integration-friendly web architecture to craft your brand's digital home<br/><br/></p>
									<a href="/brand-showcase">Know More</a>
								</div>
							</div>
						</div>
					</div>					
					<div className="col-lg-3 col-sm-6">
						<div className="feature-single-box">
							<div className="feature-number">2</div>
							<div className="feature-separator"></div>
							<div className="feature-single-box-content">
								<div className="feature-icon">
									<img src={featureimg2} alt="" />
								</div>
								<div className="feature-content">
									<h3>Result-Driven <br/>Promotions</h3>
									<p>Expert-managed campaigns on Google Ads, Facebook, and leading marketplaces, harmonizing sales with ROAS</p>
									<a href="/result-driven-promotions">Know More</a>
								</div>
							</div>
						</div>
					</div>					
					<div className="col-lg-3 col-sm-6">
						<div className="feature-single-box">
							<div className="feature-number">3</div>
							<div className="feature-separator"></div>
							<div className="feature-single-box-content">
								<div className="feature-icon">
									<img src={featureimg3} alt="" />
								</div>
								<div className="feature-content">
									<h3>Marketplace <br/>Mastery</h3>
									<p>Precision-guided sales strategy & delivery on different marketplaces, featuring Amazon & Flipkart</p>
									<a href="/marketplace-mastery">Know More</a>
								</div>
							</div>
						</div>
					</div>					
					<div className="col-lg-3 col-sm-6">
						<div className="feature-single-box">
							<div className="feature-number">4</div>
							<div className="feature-separator"></div>
							<div className="feature-single-box-content">
								<div className="feature-icon">
									<img src={featureimg1} alt="" />
								</div>
								<div className="feature-content">
									<h3>Graphic Designing and Video Editing</h3>
									<p>Visual Solutions & Film Edits: Crafting Brand Stories with Precision & Panache<br/><br/></p>
									<a href="/graphic-designing-and-video-editing">Know More</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
        </section>
    </>
  );
}
export default Powerhouse;
