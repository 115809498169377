import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import wordpressApiUrl from '../config';
import FooterForm from './FooterForm';
import email from '../images/email.png';
import phone from '../images/phone-call.png';
import india from '../images/india.png';
import uk from '../images/united-kingdom.png';
import linkedin from '../images/linkedin.png';
import facebook from '../images/facebook.png';
import instagram from '../images/instagram.png';
const Footer = () => {
  const [pages, setPages] = useState([]);

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await fetch(`${wordpressApiUrl}/pages?per_page=3`);
        const pagesData = await response.json();
        setPages(pagesData);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPages();
  }, []);

  // Random contact information
  const contactInfo = {
    phone: "+919540797657",
    email: "info@ezcommerce.in",
    address: "91 Springboard Business Hub Pvt Ltd, Noida Sector 2, India",
	addressuk: "138, Westlink House | 981 Great West Road Brentford, TW8 9DN",
  };

  return (
  
     <footer className="footer-section">
		<div className="footer-top">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-md-6">
						<div className="contact-content pt-5">
							<div className="section-header">
								<h3 className="wow fadeInUp mb-3" data-wow-duration="1.6s" data-wow-delay=".6s">Ready to Power Dock Your Brand? <span>Let's Discuss!</span></h3>
								<p>Got a great idea or looking for a remotely dedicated team?</p>
								<p>Simply reach us and see what we can do for you.</p>
							</div>
							<div className="row">
								<div className="col-lg-6 col-md-12">
									<div className="contact-item">
										<div className="contact-item-icon">
											<img src={email} alt="" />
										</div>
										<div className="contact-item-content">
											<h4>Email Us</h4>
											<p><a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a></p>
										</div>
									</div>
								</div>
								<div className="col-lg-6 col-md-12">
									<div className="contact-item">
										<div className="contact-item-icon">
											<img src={phone} alt="" />
										</div>
										<div className="contact-item-content">
											<h4>Call Us</h4>
											<p><a href={`tel:${contactInfo.phone}`}>{contactInfo.phone}</a></p>
										</div>
									</div>
								</div>
							</div>
							<h4>Our Offices</h4>
							<div className="contact-item contact-office">
								<div className="contact-item-icon">
									<img src={india} alt="" />
								</div>
								<div className="contact-item-content">
									<p>{contactInfo.address} </p>
								</div>
							</div>
							<div className="contact-item contact-office">
								<div className="contact-item-icon">
									<img src={uk} alt="" />
								</div>
								<div className="contact-item-content">
									<p>{contactInfo.addressuk}</p>
								</div>
							</div>
							<h4 className="pt-5">Follow Us</h4>
							<div className="social-icon">
                                <a href="https://www.linkedin.com/company/ezcommerceindia" target="_blank" rel="nofollow"><img src={linkedin} alt="" /></a>
                                <a href="https://www.facebook.com/EZCommerceHQ/"  target="_blank" rel="nofollow"><img src={facebook} alt="" /></a>
								<a href="#"><img src={instagram} alt="" /></a>
                            </div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6">
						<div className="contact-form">
						<div className="section-header">
							<h3 className="wow fadeInUp pb-4" data-wow-duration="1.6s" data-wow-delay=".6s">Got a brand vision? Want the Power Dock Advantage? <span>Reach out!</span></h3>
								   <FooterForm/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>		
		<div className="footer-bottom">
			<div className="container">
				<div className="row">
					<div className="col-lg-12">
							<h4>The World's No. 1 Company with the Power Dock for Brands</h4>
							<p>Copyright © 2023 EZ Commerce Pvt. Ltd. All rights reserved. <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-and-conditions">Terms of Services</a></p>
					</div>
				</div>
			</div>
		</div>
    </footer>
  );
};

export default Footer;
