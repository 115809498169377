import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo-ez-w.png';
import { FaChevronDown } from 'react-icons/fa';
import $ from 'jquery';

const NavbarComponent = ({ blogPages }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    function handleMenuClick(e) {
      var element = $(this).parent("li");
      if (element.hasClass("open")) {
        element.removeClass("open");
        element.find("li").removeClass("open");
        element.find("ul").slideUp(3000, "swing");
      } else {
        element.addClass("open");
        element.children("ul").slideDown(300, "swing");
        element.siblings("li").children("ul").slideUp(3000, "swing");
        element.siblings("li").removeClass("open");
        element.siblings("li").find("li").removeClass("open");
        element.siblings("li").find("ul").slideUp(3000, "swing");
      }
    }

    function applyMenuClickHandler() {
      if ($(window).width() <= 1200) {
        $(".main-menu li a").on("click", handleMenuClick);
      } else {
        $(".main-menu li a").off("click", handleMenuClick);
      }
    }

    $(".header-bar").on("click", function (e) {
      $(".main-menu, .header-bar").toggleClass("active");
    });

    applyMenuClickHandler();

    $(window).resize(function () {
      applyMenuClickHandler();
    });

    return () => {
      $(".main-menu li a").off("click", handleMenuClick);
      $(window).off("resize");
    };
  }, []); // Empty dependency array ensures this runs once after component mount

  return (
    <div className="header-section">
      <div className="container">
        <div className="header-wrapper">
          <div className="logo-menu">
            <Link to="/" className="logo"><img src={logo} alt="Logo" /></Link>
          </div>
          <div className="header-bar d-xl-none" onClick={toggleNav}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <ul className={`main-menu ${isNavOpen ? 'active' : ''}`}>
            <li>
              <a href="/about-us">About Us</a>
            </li>
            <li>
              <a href="#">Services <FaChevronDown /></a>
              <ul className="sub-menu">
                <li><a href="/branding">Branding</a></li>
                <li><a href="/amazon-marketing-services">Amazon Marketing Services</a></li>
                <li><a href="/performance-marketing">Performance Marketing</a></li>
                <li><a href="/data-analytics-automation">Data Analytics &amp; Automation</a></li>
                <li><a href="/testing">Testing</a></li>
                <li><a href="/web-development">Web Development</a></li>
              </ul>
            </li>
            <li>
              <a href="/case-studies">Case Studies</a>
            </li>
            <li>
              <a href="/category/blog/">Blog</a>
            </li>
            <li>
              <a href="/contact-us">Contact Us</a>
            </li>
            <li className="m-0 menu_info ms-0 ms-xl-3 mt-4 mt-xl-0">
              <a href="/request-free-audit" className="free-quote-btn">Request Free Audit</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavbarComponent;
